// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { useModal } from './ModalContext';
import { styled } from '@mui/system';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

function FeedItems() {

    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [feedItems, setFeedItems] = useState([]);
    const [news, setNews] = useState([]);
    const [newsUrl, setNewsUrl] = useState("");

    const [source, setSource] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [updatingId, setUpdatingId] = useState(null);
    const [updatingName, setUpdatingName] = useState('');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - feedItems.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/getFeedItems`, {
            method: 'GET',
            headers: headers
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setFeedItems(data.feedItems)
                setIsLoading(false)
            });
    }, []);

    async function fetchOgTags(url) {
        setNewsUrl(url)
        try {
            fetch(`${process.env.REACT_APP_API_URL}/getMetaTags`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ url: url })
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    let newTitle = data.siteName;
                    if (typeof data.siteName == "string") {
                        newTitle = data.siteName.split(" ");
                        newTitle = newTitle[0];
                    }
                    setSource(newTitle);
                    setTitle(data.ogTitle);
                    setDescription(data.ogDescription);
                    setImage(data.ogImage);
                })
        } catch (error) {
            console.error('Failed to fetch Open Graph tags:', error);
            return null;
        }
    }

    const handleCreateCategory = () => {
        setIsUpdating(true);
        fetch(`${process.env.REACT_APP_API_URL}/category`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ name: newCategoryName }),
        }).then((e) => {
            console.log(e)
            setNewCategoryName('');
            setShowModal(false);
            refreshCategories();
            setIsUpdating(false)
        });
    };

    const handleUpdateCategory = () => {
        fetch(`${process.env.REACT_APP_API_URL}/category/${updatingId}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({ name: updatingName }),
        }).then(() => {
            setUpdatingId(null);
            setUpdatingName('');
            refreshCategories();
        });
    };

    const handleDeleteCategory = (id) => {

        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (!isConfirmed) {
            return false;
        }

        fetch(`${process.env.REACT_APP_API_URL}/category`, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify({ id }),
        }).then(() => {
            refreshCategories();
        });
    };

    const refreshCategories = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/categories?`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setIsLoading(false);
                setNews(data)
            });
    };

    function formatDate(dateStr) {
        // Convert the input string to a Date object
        let date = new Date(dateStr);

        // Adjust for GMT+1
        date.setHours(date.getHours() + 1);

        // Get the individual components
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes().toString().padStart(2, '0');
        let day = date.getUTCDate().toString().padStart(2, '0');
        let month = date.toLocaleString('default', { month: 'long' });
        let year = date.getUTCFullYear().toString().slice(-2);

        // Determine AM or PM
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Convert hour '0' to '12'
        hours = hours.toString().padStart(2, '0');

        // Format the date
        let formattedDate = `${hours}:${minutes} ${ampm} ${day}, ${month}, ${year}`;

        return formattedDate;
    }

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    <Row>
                        <Col lg="11">

                            {isUpdating &&
                                <div>
                                    <span className="loader"></span>
                                </div>
                            }

                            <h1>Feed</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <>
                                        <Placeholder animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                    </>

                                }

                                {!isLoading &&
                                    <>
                                        <Button onClick={() => setShowModal(true)} style={{ margin: "10px" }}>Process Feed</Button>
                                        <Button onClick={() => setShowModal(true)} style={{ margin: "10px" }}>Add</Button>
                                        <Button onClick={() => setShowModal(true)} style={{ margin: "10px" }}>Delete</Button>
                                        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                                            <Col>

                                                <Form.Group>
                                                    <Form.Label>Filter By Category</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedCategory}
                                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                                    >
                                                        <option value="">All Categories</option>

                                                    </Form.Control>
                                                </Form.Group>

                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Filter By Course Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={searchKeyword}
                                                        onChange={(e) => {
                                                            setSearchKeyword(e.target.value);
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>

                                            </Col>
                                        </Row>

                                        <Root sx={{ maxWidth: '100%' }}>
                                            <table aria-label="custom pagination table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Title</th>
                                                        <th>Source</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(rowsPerPage > 0
                                                        ? feedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : feedItems
                                                    ).map((row) => (
                                                        <tr key={row.id}>
                                                            <td><input type='checkbox' /></td>
                                                            <td>{row.title}</td>
                                                            <td>{row.source}</td>
                                                            <td>{formatDate(row.postDate)}</td>
                                                        </tr>
                                                    ))}
                                                    {emptyRows > 0 && (
                                                        <tr style={{ height: 41 * emptyRows }}>
                                                            <td colSpan={4} aria-hidden />
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <CustomTablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                            colSpan={4}
                                                            count={feedItems.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            slotProps={{
                                                                select: {
                                                                    'aria-label': 'rows per page',
                                                                },
                                                                actions: {
                                                                    showFirstButton: true,
                                                                    showLastButton: true,
                                                                },
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </Root>
                                    </>
                                }
                            </div>

                        </Col>
                    </Row>

                </Col>

            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        type="text"
                        placeholder="Category Name"
                        value={newsUrl}
                        onChange={(e) => { fetchOgTags(e.target.value) }}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Source"
                        value={source}
                        readOnly={true}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        value={title}
                        readOnly={true}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Description"
                        value={description}
                        readOnly={true}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Image"
                        value={image}
                        readOnly={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setNewsUrl(""); setShowModal(false) }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateCategory}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Root = styled('div')(
    ({ theme }) => `
    table {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 8px;
    }
  
    th {
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    }
    `,
);

const CustomTablePagination = styled(TablePagination)`
    & .${classes.toolbar} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.actions} {
      display: flex;
      gap: 0.25rem;
    }
  `;

export default FeedItems;
