import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ModalProvider } from './pages/ModalContext';
import ProtectedRoute from './components/ProtectedRoute'

import Home from './pages/home';
import NewsScreen from './pages/news'
import CategoriesScreen from './pages/categories';
import CourseScreen from './pages/courses';
import Login from './pages/Login'
import EditCourse from './pages/editCourse';
import Quizzes from './pages/quizzes';
import EditQuiz from './pages/editQuiz';
// import Staff from './pages/staff';
import UserRoles from './pages/userRoles';
import NotFoundPage from './pages/NotFound';
import Onboard from './pages/onboard';

import Profile from './pages/profile';
import Students from './pages/students';
import EditStudent from './pages/editStudent';
import Configuration from './pages/configuration';
import Chat from './pages/Chat';
import Messages from './pages/messages';
import Enrollments from './pages/enrollments';
import FeedItems from './pages/feedItems';


import Members from './pages/members';
import EditMember from './pages/editMember';
import State from './pages/state';


import Applications from './pages/applications';
import EditApplications from './pages/editApplications';

import Lga from './pages/lga';

function App() {

  return (
    <>
      <ModalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

          <Route path="/member" element={<Members />} />
          <Route path="/editMember/:menberID" element={<ProtectedRoute><EditMember /></ProtectedRoute>} />
          <Route path="/statelist" element={<State />} />
          <Route path="/applications" element={<ProtectedRoute><Applications /></ProtectedRoute>} />
          <Route path="/editApplications/:applicationsID" element={<ProtectedRoute><EditApplications /></ProtectedRoute>} />
          <Route path="/Viewlag/:stateId/:LGANAME"  element={<ProtectedRoute><Lga /></ProtectedRoute>} />


            <Route path="/feedItems" element={<FeedItems />} />
            <Route path="/news" element={<NewsScreen />} />
            <Route path="/onboard/:invitationCode" element={<Onboard />} />
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/categories" element={<ProtectedRoute><CategoriesScreen /></ProtectedRoute>} />
            <Route path="/courses" element={<ProtectedRoute><CourseScreen /></ProtectedRoute>} />
            <Route path="/enrollments" element={<ProtectedRoute><Enrollments /></ProtectedRoute>} />
            <Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
            <Route path="/editCourse" element={<ProtectedRoute><EditCourse /></ProtectedRoute>} />
            <Route path="/quizzes" element={<ProtectedRoute><Quizzes /></ProtectedRoute>} />
            <Route path="/editQuiz" element={<ProtectedRoute><EditQuiz /></ProtectedRoute>} />
            
            <Route path="/students" element={<ProtectedRoute><Students /></ProtectedRoute>} />
           
            <Route path="/editStudent/:studentId" element={<ProtectedRoute><EditStudent /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/configuration" element={<ProtectedRoute><Configuration /></ProtectedRoute>} />
            <Route path="/chat/:receiverId" element={<ProtectedRoute><Chat /></ProtectedRoute>} />


            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ModalProvider>
      <ToastContainer />
    </>
  );
}

export default App;
