import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, ListGroup, Table } from 'react-bootstrap';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate } from "react-router-dom";

function Home() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const permissions = useSelector(state => state.user.user.permissions);

    const [coursesAccess, setCoursesAccess] = useState(false);
    const [paymentsAccess, setPaymentsAccess] = useState(false);
    const [usersAccess, setUsersAccess] = useState(false);

    useEffect(() => {
        for (var a = 0; a <= permissions.length; a++) {
            if (permissions[a]) {
                if (permissions[a].module == "COURSES") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setCoursesAccess(userPermissions.read);
                }
                if (permissions[a].module == "PAYMENTS") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setPaymentsAccess(userPermissions.read);
                }
                if (permissions[a].module == "STAFF") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setUsersAccess(userPermissions.read);
                }
            }
        }
    }, [])

    return (
        <>
            <Header />
            <>
                <Row className='home_outer'>

                    <Menu />

                    <Col className='right_home'>

                        <Row>
                            <Col lg="11">

                                <h1>APGA Admin Dashboard</h1>

                                <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                    <Row>
                                        <Col lg="4">

                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Members</Card.Title>
                                                    {paymentsAccess &&
                                                        <>
                                                            <Card.Text>
                                                                Click below to view all students
                                                            </Card.Text>

                                                            <Button variant="primary">View All Students</Button>
                                                        </>
                                                    }
                                                </Card.Body>
                                                <Table striped bordered hover>
                                                    <tbody>
                                                       
                                                        <tr>
                                                            <td>Total Members</td><td>---</td>
                                                        </tr>
                                                      
                                                    </tbody>
                                                </Table>
                                            </Card>

                                        </Col>

                                        <Col lg="4">

                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Ward</Card.Title>
                                                    {paymentsAccess &&
                                                        <>
                                                            <Card.Text>
                                                                Click below to view all Payments
                                                            </Card.Text>

                                                            <Button variant="primary">View All Payments</Button>
                                                        </>
                                                    }
                                                </Card.Body>
                                                <Table striped bordered hover>
                                                    <tbody>
                                                        <tr>
                                                            <td>Toatl Ward</td><td>---</td>
                                                        </tr>
                                                       
                                                    </tbody>
                                                </Table>
                                            </Card>

                                        </Col>

                                        <Col lg="4">

                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Polling Unit. </Card.Title>

                                                    {coursesAccess &&
                                                        <>
                                                            <Card.Text>
                                                                Click below to view all Courses
                                                            </Card.Text>

                                                            <Button variant="primary">View All Courses</Button>
                                                        </>
                                                    }
                                                </Card.Body>
                                                <Table striped bordered hover>
                                                    <tbody>
                                                        <tr>
                                                            <td>Total Polling Unit. </td><td>--</td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </Table>
                                            </Card>

                                        </Col>

                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </Col>



                </Row>

            </>
        </>
    )

}

export default Home;