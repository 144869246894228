// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';

function Messages() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })
    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([]);

    // Load courses and categories from the API
    useEffect(() => {
        getMessages();
    }, []);

    const getMessages = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/allMessages/${userId}`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {

                if (data.message == "token_expired") {
                    openModal();
                    return;
                }

                if (data.feedback == "success") {
                    console.log(data.data)
                    setMessages(data.data)
                    setIsLoading(false)

                } else {
                    setIsLoading(false)
                }

            });
    }

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col>
                            <h1>Messages</h1>
                        </Col>
                    </Row>

                    <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Message</th>
                                                <th>From</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {messages.map((message, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={`/chat/${message.userId}`} style={{ color: "black", textDecoration: "none" }}>
                                                            {message.isRead == 0 && message.type == 'text' ?
                                                                <b> {message.content}</b>
                                                                : ''
                                                            }
                                                            {message.isRead != 0 && message.type == 'text' ?
                                                                <> {message.content}</>
                                                                : ''
                                                            }

                                                            {message.type == "image" ?
                                                                <div style={{ width: '100px' }} dangerouslySetInnerHTML={{ __html: message.content }}></div>
                                                                : ''}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {message.username}
                                                    </td>
                                                    <td>
                                                        <a href={`/chat/${message.userId}`}>
                                                            <Button variant="info">
                                                                View
                                                            </Button>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </>

                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

        </>
    );
}

export default Messages;
