// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

// You would define your reducers like so:
const userReducer = (state = {}, action) => {
  switch(action.type) {
    case 'LOGIN':
      return { ...state, user: action.user };
    case 'LOGOUT':
      return { ...state, user: null };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  // ...your other reducers here
  // you can combine several reducers to build the redux store
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // only persist user reducer
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);
