import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import Logo from '../apga-logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { toast } from 'react-toastify';

function Header() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((e) => {
        return e.user.user;
    })

    const permissions = useSelector(state => {
        if (state.user.user) {
            return state.user.user.permissions;
        }
    });

    const [categoryAccess, setCategoryAccess] = useState(false);
    const [coursesAccess, setCoursesAccess] = useState(false);
    const [quizzesAccess, setQuizzesAccess] = useState(false);
    const [enrollmentsAccess, setEnrollmentAccess] = useState(false);
    const [subscriptionPlansAccess, setSubscriptionPlansAccess] = useState(false);
    const [subscriptionsAccess, setSubscriptionsAccess] = useState(false);
    const [paymentsAccess, setPaymentsAccess] = useState(false);
    const [usersAccess, setUsersAccess] = useState(false);

    var w = 20 / 100;

    let theWidth = parseInt(window.innerWidth) - parseInt(window.innerWidth * w);

    if (window.innerWidth > 500) {

        w = 50 / 100;

        theWidth = parseInt(window.innerWidth) - parseInt(window.innerWidth * w);

    }

    const navBarStyle = { backgroundColor: '#026334', color: '#fff' };

    const whiteColor = { color: "#fff" }

    const [showDropdown, setShowDropdown] = useState(false);

    const handleToggle = (isOpen, event) => {
        // Using the event type to determine if an item was selected
        if (event.type === 'click') {
            setShowDropdown(!isOpen);
        }
    };

    useEffect(() => {
        if (user != null) {
            for (var a = 0; a <= permissions.length; a++) {
                if (permissions[a]) {

                    if (permissions[a].module == "CATEGORIES") {
                        const userPermissions = JSON.parse(permissions[a].permissions)
                        setCategoryAccess(userPermissions.read);
                    }

                    if (permissions[a].module == "COURSES") {
                        const userPermissions = JSON.parse(permissions[a].permissions)
                        setCoursesAccess(userPermissions.read);
                    }

                    if (permissions[a].module == "QUIZZES") {
                        const userPermissions = JSON.parse(permissions[a].permissions)
                        setQuizzesAccess(userPermissions.read);
                    }

                    if (permissions[a].module == "PAYMENTS") {
                        const userPermissions = JSON.parse(permissions[a].permissions)
                        setPaymentsAccess(userPermissions.read);
                    }

                    if (permissions[a].module == "STAFF") {
                        const userPermissions = JSON.parse(permissions[a].permissions)
                        setUsersAccess(userPermissions.read);
                    }

                }
            }

            const userID = user.userId; // Replace this with actual user ID
        }
    }, [])

    return (
        <Navbar expand="md" style={navBarStyle}>
            <Container>
                <Navbar.Brand href="#home" style={whiteColor}>

                    <img src={Logo} style={{ height: 25, width: 25, padding: 0, marginRight: 5 }} />

                    <span style={{ paddingLeft: "0", marginTop: 30, fontFamily: 'charcoal', fontStyle: "normal", fontWeight: 800, fontSize: "17px", lineHeight: "16px", color: "#fff" }}>APGA</span>
                </Navbar.Brand>

                {/* Always collapsed element */}
                {user != null &&
                    <>
                        <Button variant='primary' onClick={() => { navigate(-1); }} style={{color:"#fff"}}>Go back</Button>
                        <div className="d-block d-lg-none">
                            <Dropdown show={showDropdown} onToggle={handleToggle}>
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    title="Menu"
                                    onClick={() => setShowDropdown(!showDropdown)}
                                >
                                    <Dropdown.Item href="/">Home</Dropdown.Item>
                                    {categoryAccess &&
                                        <Dropdown.Item href="/categories">Categories</Dropdown.Item>
                                    }
                                    {coursesAccess &&
                                        <Dropdown.Item href="/courses">Courses</Dropdown.Item>
                                    }
                                    {quizzesAccess &&
                                        <Dropdown.Item href="/quizzes">Quizzes</Dropdown.Item>
                                    }
                                    {usersAccess &&
                                        <>
                                            <Dropdown.Item href="/staff">Staff</Dropdown.Item>
                                            <Dropdown.Item href="/students">Students</Dropdown.Item>
                                        </>
                                    }
                                    <Dropdown.Item href="/profile">My Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { dispatch({ type: 'LOGOUT' }); navigate("/Login") }}>Logout</Dropdown.Item>
                                </DropdownButton>
                            </Dropdown>
                        </div>
                    </>
                }
            </Container>
        </Navbar>

    )

}

export default Header;