// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';

function Applications() {

  const userId = useSelector((e) => {
    return e.user.user.userId;
  })

  const { openModal } = useModal();
  const accessToken = useSelector(state => state.user.user.accessToken);

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [allApplications, setaddApplications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [applicationsEmail, setApplicationsEmail] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [adding, setAdding] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  useEffect(() => {

    getaddApplications()

  }, []);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const addNewApplications = () => {

    if (!applicationsEmail) {
      toast.error("Email is required", {
        position: "top-center"
      })
      return;
    }

    if (!validateEmail(applicationsEmail)) {
      toast.error("Invalid Email", {
        position: "top-center"
      })
      return;
    }
    // Prepare the new course data
    const userData = {
      email: applicationsEmail,
      userId: userId,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };

    setAdding(true);

    // Send a POST request to the server to create a new course
    fetch(`${process.env.REACT_APP_API_URL}/Applications`, {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setAdding(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

        if (data.feedback != "success") {

          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

        if (data.feedback == "success") {

          toast.success("Invitation sent!", {
            position: "top-center"
          })

          getaddApplications();

          setApplicationsEmail("")

          setAdding(false);

          setShowModal(false);

        }

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const getaddApplications = () => {
    setIsLoading(true);

    let queryUrl = `${process.env.REACT_APP_API_URL}/member?status=0`;

    if (selectedCategory != "" || searchKeyword != "") {
      queryUrl = `${process.env.REACT_APP_API_URL}/member?status=${selectedCategory}&keyWord=${searchKeyword}`;

    } else if (selectedCategory == "") {

      queryUrl = `${process.env.REACT_APP_API_URL}/member?status=0`;

    }

    fetch(queryUrl, {
      method: 'GET',
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message == "token_expired") {
          openModal();
          return;
        }
        setaddApplications(data.data)
        setIsLoading(false)
      });
  }

  const deleteApplications = async () => {

    if (!selectedID) {
      toast.error("Something isn't right, try again!", {
        position: "top-center"
      })
      return;
    }

    setAdding(true);

    const postData = { requestKey: process.env.REACT_APP_SERVER_KEY, userId: selectedID };

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteapplications`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers,
      })

      const response = await request.json();
      if (response.message == "token_expired") {
        openModal();
        return;
      }
      if (response.feedback == "success") {
        getaddApplications();
        setSelectedID("");
        toast.success("Applications deleted successfully", {
          position: "top-center"
        })
        setShowDeleteModal(false)
      } else {
        toast.error(response.message, {
          position: "top-center"
        })
      }

      setAdding(false);


    } catch (e) {
      setAdding(false);
    }
  }

  useEffect(() => {

    if (selectedCategory != "" || searchKeyword != "") {
      getaddApplications();
    } else if (selectedCategory == "") {
      getaddApplications();
    }

  }, [selectedCategory, searchKeyword])

  return (
    <>
      <Header />

      <Row className='home_outer'>

        <Menu />

        <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>Applications</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            {/* <Row>
              <Col>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add New Applicationss
                </Button>
              </Col>
            </Row> */}

            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col>

                <Form.Group>
                  <Form.Label>Filter By Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {/* <option value="1">Active</option> */}
                    <option value="0">Inactive</option>
                  </Form.Control>
                </Form.Group>

              </Col>

              <Col>

                <Form.Group>
                  <Form.Label>Filter By  Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>

              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allApplications.map((applications) => (
                        <tr key={applications.Id}>
                             
                          <td>{applications.title} {applications.firstName} {applications.middleName} {applications.lastName}</td>
                          <td>{applications.phoneNumber}</td>
                          <td>{applications.email}</td>
                          <td>{applications.status == 1 ? <>Active</> : <>Inactive</>}</td>
                          <td>
                            <Button variant="info" onClick={() => navigate(`/editApplications/${applications.Id}`)}>
                              View
                            </Button>
                          </td>
                          <td>
                            <Button variant="danger" onClick={() => {
                              setSelectedID(applications.Id);
                              setShowDeleteModal(true);
                            }}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Applications</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            value={applicationsEmail}
                            onChange={(e) => setApplicationsEmail(e.target.value)}
                          />
                        </Form.Group>
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={addNewApplications}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </>

              </Col>
            </Row>

          </div>

        </Col>

      </Row>

      {/* Modal for Deleting Permission */}
      <Modal show={showDeleteModal} onHide={() => {
        setSelectedID("");
        setShowDeleteModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Applications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a permanent and irreversible action!
          </p>
          <p>
            Click yes if you are sure!!!
          </p>

          {adding &&
            <center>
              <div>
                <span className="loader"></span>
              </div>
            </center>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deleteApplications}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => {
            setSelectedID("");
            setShowDeleteModal(false);
          }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Applications;
