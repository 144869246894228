// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';

function CourseScreen() {

  const userId = useSelector((e) => {
    return e.user.user.userId;
  })
  const {openModal} = useModal();
  const accessToken = useSelector(state => state.user.user.accessToken);

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [newCourse, setNewCourse] = useState({ name: '', category_id: '' });
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [courseOrderingModal, setCourseOrderingModal] = useState(false);
  const [showReOrderButton, setShowReOrderButton] = useState(false);
  const [adding, setAdding] = useState(false);

  const [selectedID, setSelectedID] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Load courses and categories from the API
  useEffect(() => {

    getCourses()

    fetch(`${process.env.REACT_APP_API_URL}/categories`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.message=="token_expired"){
          return;
        }
        setCategories(data)
      });

  }, []);

  const handleCreateCourse = () => {

    if (!newCourse.name || !newCourse.category_id) {
      toast.error("All fields are required", {
        position: "top-center"
      })
      return;
    }
    // Prepare the new course data
    const courseData = {
      name: newCourse.name,
      category_id: newCourse.category_id,
    };

    setAdding(true);

    // Send a POST request to the server to create a new course
    fetch(`${process.env.REACT_APP_API_URL}/courses`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(courseData),
    })
      .then((response) => {
        setAdding(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {
        if (data.success) {

          setNewCourse({ name: '', category_id: '' });

          navigate(`/editCourse?course=${data.insertId}`)

          setAdding(false);

        }

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const getCourses = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/courses`, {
      method: 'GET',
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message == "token_expired") {
          openModal();
          return;
        }
        setCourses(data)
        setIsLoading(false)
      });
  }

  const handleUpdateCourse = (courseId) => {
    // TODO: Implement update course API call
    navigate(`/editCourse?course=${courseId}`)
  };

  const handleDeleteCourse = (courseId) => {
    setSelectedID(courseId);
    setShowDeleteModal(true);
  };

  const getCoursesByCategory = async () => {

    setIsLoading(true);

    const request = await fetch(`${process.env.REACT_APP_API_URL}/courses?categoryId=${selectedCategory}&keyWord=${searchKeyword}`,{
      method:'GET',
      headers: headers,
    });

    const response = await request.json();
    if(response.message=="token_expired"){
      return;
    }
    setCourses(response);

    setIsLoading(false);

  }

  useEffect(() => {
    if (selectedCategory != "" && searchKeyword == "") {
      setShowReOrderButton(true);
    } else {
      setShowReOrderButton(false);
    }

    if (selectedCategory != "" || searchKeyword != "") {

      getCoursesByCategory();

    } else if (selectedCategory == "") {
      getCourses();
    }

  }, [selectedCategory, searchKeyword])

  function CourseItem({ course, moveCourse, index }) {
    const [, ref] = useDrag({
      type: 'COURSE',
      item: { id: course.id, index },
    });

    const [, drop] = useDrop({
      accept: 'COURSE',
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveCourse(draggedItem.index, index);
          draggedItem.index = index;
        }
      }
    });

    return (
      <div ref={(node) => ref(drop(node))}>
        {course.name}
      </div>
    );
  }

  const moveCourse = (fromIndex, toIndex) => {
    const updatedCourses = [...courses];
    const [movedCourse] = updatedCourses.splice(fromIndex, 1);
    updatedCourses.splice(toIndex, 0, movedCourse);

    setCourses(updatedCourses);

    // Send the updated positions to your backend
    fetch(`${process.env.REACT_APP_API_URL}/updateCoursePositions`, {
      method: 'POST',
      headers,
      body: JSON.stringify(updatedCourses.map((c, idx) => ({ id: c.id, position: idx })))
    });
  };

  const deleteCourse = async () => {

    if (!selectedID) {
      toast.error("Something isn't right, try again!", {
        position: "top-center"
      })
      return;
    }

    setAdding(true);

    const postData = { id: selectedID };

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteCourse`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: headers,
      })

      const response = await request.json();

      if (response.feedback == "success") {
        getCourses();
        setSelectedID("");
        toast.success("Course deleted successfully", {
          position: "top-center"
        })
        setShowDeleteModal(false)
      } else {
        toast.error(response.message, {
          position: "top-center"
        })
      }

      setAdding(false);


    } catch (e) {
      setAdding(false);
    }

  }

  return (
    <>
      <Header />

      <Row className='home_outer'>

        <Menu />

        <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>Courses</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            <Row>
              <Col>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add New Course
                </Button>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col>

                <Form.Group>
                  <Form.Label>Filter By Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">All Categories</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

              </Col>

              <Col>

                <Form.Group>
                  <Form.Label>Filter By Course Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>

              </Col>
            </Row>

            {showReOrderButton &&
              <Row>
                <Col>
                  <Button variant="primary" onClick={() => setCourseOrderingModal(true)}>
                    Reorder
                  </Button>
                </Col>
              </Row>
            }

            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map((course) => (
                        <tr key={course.id}>
                          <td>{course.name}</td>
                          <td>{course.categoryName}</td>
                          <td>{course.status}</td>
                          <td>
                            <Button variant="info" onClick={() => handleUpdateCourse(course.id)}>
                              View
                            </Button>
                          </td>
                          <td>
                            <Button variant="danger" onClick={() => handleDeleteCourse(course.id)}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {/* Modal for Creating New Course */}
                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Course</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Course Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={newCourse.name}
                            onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Category</Form.Label>
                          <Form.Control
                            as="select"
                            value={newCourse.category_id}
                            onChange={(e) => setNewCourse({ ...newCourse, category_id: e.target.value })}
                          >
                            <option value="">
                              Select course category
                            </option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>

                      <Button variant="primary" onClick={handleCreateCourse}>
                        Add Course
                      </Button>
                      <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button>

                    </Modal.Footer>
                  </Modal>

                  {/* Modal for Ordering Courses */}
                  <Modal show={courseOrderingModal} onHide={() => setCourseOrderingModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Reorder Courses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <DndProvider backend={HTML5Backend}>
                        {courses.map((course, idx) => (
                          <CourseItem key={course.id} course={course} moveCourse={moveCourse} index={idx} />
                        ))}
                      </DndProvider>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setCourseOrderingModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>

              </Col>
            </Row>

          </div>

        </Col>

      </Row>

      {/* Modal for Deleting course */}
      <Modal show={showDeleteModal} onHide={() => {
        setSelectedID("");
        setShowDeleteModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a permanent and irreversible action!
          </p>
          <p>
            Click yes if you are sure!!!
          </p>

          {adding &&
            <center>
              <div>
                <span className="loader"></span>
              </div>
            </center>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deleteCourse}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => {
            setSelectedID("");
            setShowDeleteModal(false);
          }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default CourseScreen;
