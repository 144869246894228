// App.js
import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { useModal } from './ModalContext';
import { useParams } from 'react-router-dom';
import { useNotice } from '../Providers/BroadcastProvider';
import { toast } from 'react-toastify';

function Chat() {

    const audioRef = useRef();

    const playNotificationSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const { openModal } = useModal();

    const { setMessageReceivedCallback, message } = useNotice();

    const userId = useSelector((e) => {
        if (e.user.user) {
            return e.user.user.userId
        }
    })

    let { receiverId } = useParams();

    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setFile(file);
        setSelectedImage(file);
    };

    const clearImage = () => {
        setSelectedImage(null);
    };

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isUpdating, setIsUpdating] = useState(false)
    const [file, setFile] = useState(null);
    const messageContainerRef = useRef(null);
    const [sendingMessage, setSendingMessage] = useState(false);

    const generateRandom = () => {
        const min = 100000; // Minimum 6-digit number
        const max = 999999; // Maximum 6-digit number
        const random6DigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        return random6DigitNumber;
    };

    const handleSendMessage = () => {
        let messageId = generateRandom();
        postMessage(messageId)
        if (newMessage.trim() !== '') {
            const newMessageObj = {
                id: messages.length + 1,
                text: newMessage,
                sender: 'user',
            };
            //setMessages([...messages, newMessageObj]);
            setNewMessage('');
        }
    };

    const postMessage = async (messageId) => {
        setSendingMessage(true);
        toast.dismiss();
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('receiverId', receiverId);
        formData.append('message', newMessage);
        formData.append('messageId', messageId);
        formData.append('file', file);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/messages`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })

        const response = await request.json();

        if (response.feedback == "success") {
            setSendingMessage(false);
            setFile(null);
            setSelectedImage(null)
            getMessages();
        } else {
            toast.error(
                <>
                    {"An error occurred"}{" "}
                </>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                })
            setSendingMessage(false);
        }
    }

    const getMessages = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/messages?userId=${userId}&receiverId=${receiverId}`, {
                method: "GET",
                headers,
            })

            const response = await request.json();

            setMessages(response.data)

        } catch (e) {

        }

    }

    useEffect(() => {
        getMessages();
    }, [])

    const ref = useRef(null);

    // Scroll to the bottom of the message container when new messages are added
    useEffect(() => {
        ref.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }, [messages]);

    useEffect(() => {
        toast.dismiss();
        if (message) {
            playNotificationSound();
            toast.info(
                <>
                    {message}{" "}
                </>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                }
            );
        }
        getMessages();
    }, [message])

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    <Row>
                        <Col lg="11">

                            {isUpdating &&
                                <div>
                                    <span className="loader"></span>
                                </div>
                            }

                            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#f0f4f8', position: 'relative' }}>
                                <div style={{ padding: '1rem', backgroundColor: '#1e90ff', color: '#fff', textAlign: 'center' }}>Chat</div>
                                <div
                                    id="messageContainer"
                                    ref={messageContainerRef}
                                    style={{ flex: '1', overflowY: 'auto', overflowX: 'hidden', padding: '1rem', paddingBottom: '4rem' }}
                                >
                                    {messages.map((message) => (
                                        <div
                                            key={message.id}
                                            style={{
                                                marginBottom: '0.5rem',
                                                padding: '0.5rem',
                                                borderRadius: '0.5rem',
                                                backgroundColor: message.senderUserId === userId ? '#e3f2fd' : '#f3f4f6',
                                                color: message.senderUserId === userId ? '#1e90ff' : '#333',
                                                //maxWidth: '25rem',
                                                maxWidth: '80%',
                                                marginLeft: message.senderUserId === userId ? 'auto' : '0',
                                                marginRight: message.senderUserId === userId ? '0' : 'auto',
                                            }}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                                        </div>
                                    ))}
                                    <div ref={ref} />
                                </div>
                                <div style={{ width: '100%', height: '10rem', position: 'relative' }}>
                                    <div style={{ width: "83%", padding: '1rem', display: 'flex', position: 'fixed', bottom: '0', right: '0', backgroundColor: '#fff' }}>
                                        {selectedImage ? (
                                            <div style={{ position: 'relative', flex: '1' }}>
                                                <img
                                                    src={URL.createObjectURL(selectedImage)}
                                                    alt="Selected Image"
                                                    style={{ maxWidth: '120px', borderRadius: '0.5rem' }}
                                                />
                                                <button
                                                    onClick={() => setSelectedImage(null)}
                                                    style={{ position: 'absolute', top: '0', right: '0', backgroundColor: '#ff4500', color: '#fff', padding: '0.25rem 0.5rem', borderRadius: '50%', cursor: 'pointer' }}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <input
                                                    type="text"
                                                    style={{ flex: '1', padding: '0.5rem', borderRadius: '2rem', border: '1px solid #ccc', width: '100%' }}
                                                    placeholder="Type a message..."
                                                    value={newMessage}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSendMessage();
                                                        }
                                                    }}
                                                />
                                                <label
                                                    style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem', backgroundColor: '#1e90ff', color: '#fff', borderRadius: '2rem', cursor: 'pointer' }}
                                                >
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageUpload}
                                                    />
                                                    File
                                                </label>
                                            </>
                                        )}
                                        <button
                                            disabled={sendingMessage}
                                            style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem', backgroundColor: '#1e90ff', color: '#fff', borderRadius: '2rem', cursor: 'pointer' }}
                                            onClick={handleSendMessage}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </Col>

            </Row>
            <audio ref={audioRef}>
                <source src="/alert.mp3" type="audio/mpeg" />
            </audio>
        </>
    );
}

export default Chat;
