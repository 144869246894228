import React, { useState } from "react";
import fetch from 'node-fetch';
import Header from "../components/header";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useDispatch, useSelector } from 'react-redux';
import { loginStart, loginSuccess, loginFailure } from '../redux/auth/authenticationSlice';
import { useHistory, useNavigate } from 'react-router-dom';
import { config } from "../config";

function NotFoundPage(props) {

    const [dataLoading, setDataLoading] = useState(false);

    const [username, setUsername] = useState("admin");

    const [password, setPassword] = useState("Pass400123@");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5" style={{ padding: 20 }}>

                            <Card className="shadow-lg">
                                <Card.Body>
                                    
                                    <h1>Page not found</h1>
                                    <div className="text-center">
                                            <Button
                                                variant="primary"
                                                onClick={()=>{
                                                    navigate("/");
                                                }}
                                            >
                                                Go Home
                                            </Button>
                                        </div>
                                </Card.Body>
                            </Card>

                        </div>
                    </div>
                </div>

            </>
        </>
    )

}

export default NotFoundPage;