import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate, useParams } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";
import { useModal } from "./ModalContext";

function Profile() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })

    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);
  
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', 
    };

    const navigate = useNavigate();
    const [staff, setStaff] = useState({});
    const [fullName, setFullName] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageTitle, setPageTitle] = useState("");

    const loadStaff = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/loadStaff?userId=${userId}`,{
                method: 'GET',
                headers,
            });

            const response = await request.json();
            if(response.message=="token_expired"){
                openModal();
                return;
            }

            setStaff(response.data[0])

            setFullName(response.data[0].fullName)

            console.log(response)

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (userId == null) {
            //navigate('/quizzes');
            return;
        }

        loadStaff();

    }, [])

    const updateProfile = async (e) => {

        e.preventDefault();
        
        if(!fullName){
            toast.error("Name field is compulsory",{
                position:"top-center"
            })
            return;
        }

        setIsLoading(true);

        const postData = { userId: userId, fullName: fullName, password: password }

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/updateProfile`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers,
            })

            const response = await request.json();

            if(response.feedback=="success"){
                toast.success("Profile updated successfully",{
                    position:"top-center"
                })
            }else{
                toast.error("An error occurred",{
                    position:"top-center"
                })
            }

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred",{
                position:"top-center"
            })

            setIsLoading(false);
        }

    }

    return (
        <>
            <Header />
            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isUpdating &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }


                    <Row>
                        <Col lg="11">

                            <h1>{pageTitle}</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <div>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder.Button variant="primary" xs={6} />
                                    </div>
                                }
                                {!isLoading &&
                                    <>
                                        <Row>
                                            <Col lg="12" className="">
                                                <h1>My Profile</h1>
                                                <hr />
                                                <Row>
                                                    <Col lg="7">
                                                        <Form id="myForm" onSubmit={updateProfile}>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={4}>
                                                                    Name
                                                                </Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control value={fullName} type="text" placeholder="Change password" onChange={(e)=>{
                                                                        setFullName(e.target.value);
                                                                    }} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={4}>
                                                                    Email
                                                                </Form.Label>
                                                                <Col sm={8}>
                                                                    {staff.email}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={4}>
                                                                    Username
                                                                </Form.Label>
                                                                <Col sm={8}>
                                                                    {staff.username}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={4}>
                                                                    Change Password
                                                                </Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control type="text" placeholder="Change password" value={password} onChange={(e)=>{
                                                                        setPassword(e.target.value);
                                                                    }} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3">
                                                                <Col sm={{ span: 10, offset: 2 }}>
                                                                    <Button type="submit" disabled={isLoading} >
                                                                        {isLoading ? <>Updating</> : <>Update</>}
                                                                    </Button>
                                                                </Col>
                                                            </Form.Group>

                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </>
                                }

                            </div>
                        </Col>
                    </Row>

                </Col>

            </Row>

        </>
    )

}

export default Profile;