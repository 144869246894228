// src/NoticeContext.js
import React, { useRef, createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

const BroadcastContext = createContext();

export const useNotice = () => {
    return useContext(BroadcastContext);
};

export const BroadcastProvider = ({ children }) => {

    const audioRef = useRef();

    const playNotificationSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const path = window.location.pathname;

    const checkLink = "chat";

    const isChatPage = path.includes(checkLink);

    const socket = io("http://localhost:4000", {
        withCredentials: true,
        transports: ["websocket", "polling"],
    });

    const accessToken = useSelector(state => {
        if (state.user.user) {
            return state.user.user.accessToken;
        } else {
            return null;
        }
    });

    const userId = useSelector(state => {
        if (state.user.user) {
            return state.user.user.userId;
        } else {
            return null;
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const [notice, setNotice] = useState(null);
    const [message, setMessage] = useState(null);

    // Callback function to handle the received message
    const handleReceivedMessage = (message) => {
        setMessage(message);
        toast.dismiss();

        if (isChatPage) {

        } else {
            playNotificationSound();
            toast.info(
                <>
                    You received a new message{" "}
                    <br />
                    <a href="/messages">View Message</a>.
                </>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                }
            );

        }

    };

    const showNotice = (message) => {
        setNotice(message);
    };

    const hideNotice = () => {
        setNotice(null);
    };

    useEffect(() => {
        if (userId) {
            socket.emit("register", `${userId}`);

            socket.on("received message", ({ message }) => {
                handleReceivedMessage(message);
            });
        }

        return () => {
            socket.off("received message");
        };
    }, [userId]);

    return (
        <BroadcastContext.Provider value={{ notice, showNotice, hideNotice, message }}>
            {children}
            <audio ref={audioRef}>
                <source src="/alert.mp3" type="audio/mpeg" />
            </audio>
        </BroadcastContext.Provider>
    );
};

export default BroadcastProvider;
