// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { useModal } from './ModalContext';

function CategoriesScreen() {

    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [updatingId, setUpdatingId] = useState(null);
    const [updatingName, setUpdatingName] = useState('');

    useEffect(() => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/categories`, {
            method: 'GET',
            headers: headers
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setCategories(data)
                setIsLoading(false)
            });
    }, []);

    const handleCreateCategory = () => {
        setIsUpdating(true);
        fetch(`${process.env.REACT_APP_API_URL}/category`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ name: newCategoryName }),
        }).then((e) => {
            console.log(e)
            setNewCategoryName('');
            setShowModal(false);
            refreshCategories();
            setIsUpdating(false)
        });
    };

    const handleUpdateCategory = () => {
        fetch(`${process.env.REACT_APP_API_URL}/category/${updatingId}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({ name: updatingName }),
        }).then(() => {
            setUpdatingId(null);
            setUpdatingName('');
            refreshCategories();
        });
    };

    const handleDeleteCategory = (id) => {

        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (!isConfirmed) {
            return false;
        }

        fetch(`${process.env.REACT_APP_API_URL}/category`, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify({ id }),
        }).then(() => {
            refreshCategories();
        });
    };

    const refreshCategories = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/categories?`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setIsLoading(false);
                setCategories(data)
            });
    };

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    <Row>
                        <Col lg="11">

                            {isUpdating &&
                                <div>
                                    <span className="loader"></span>
                                </div>
                            }

                            <h1>Categories</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <>
                                        <Placeholder animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                    </>

                                }
                                {!isLoading &&
                                    <>
                                        <Button onClick={() => setShowModal(true)} style={{ margin: "10px" }}>Create New Category</Button>

                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "40%" }}>Name</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categories.map((category, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {updatingId === category.id ? (
                                                                <Form.Control
                                                                    type="text"
                                                                    value={updatingName}
                                                                    onChange={(e) => setUpdatingName(e.target.value)}
                                                                />
                                                            ) : (
                                                                category.name
                                                            )}
                                                        </td>
                                                        <td>
                                                            {category.status == 1 &&
                                                                <>Active</>
                                                            }
                                                            {category.status == 0 &&
                                                                <>Inactive</>
                                                            }
                                                        </td>
                                                        <td>
                                                            {updatingId === category.id ? (
                                                                <Button onClick={handleUpdateCategory}>Save</Button>
                                                            ) : (
                                                                <Button onClick={() => { setUpdatingId(category.id); setUpdatingName(category.name); }}>Update</Button>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Button variant="danger" onClick={() => handleDeleteCategory(category.id)}>Delete</Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                }
                            </div>

                        </Col>
                    </Row>

                </Col>

            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        type="text"
                        placeholder="Category Name"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateCategory}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CategoriesScreen;
