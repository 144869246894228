// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { useModal } from './ModalContext';

function Quizzes() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })

    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', 
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [quizzes, setQuizzes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [course, setCourse] = useState({});
    const [newQuiz, setNewQuiz] = useState({ name: '', category_id: '', courseId: '' });
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedID, setSelectedID] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState("");
    const [showUploadModal, setShowUploadModal] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [courses, setCourses] = useState({ value: "", label: "" })
    const [loading, setLoading] = useState(false);

    // Load courses and categories from the API
    useEffect(() => {

        getQuizzes()

        fetch(`${process.env.REACT_APP_API_URL}/categories`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setCategories(data)
            });

    }, []);

    const handleCreateQuiz = () => {

        if (!newQuiz.name || !newQuiz.category_id || !newQuiz.courseId) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }
        // Prepare the new course data
        const quizData = {
            userId,
            name: newQuiz.name,
            category_id: newQuiz.category_id,
            courseId: newQuiz.courseId,
        };

        // Send a POST request to the server to create a new course
        fetch(`${process.env.REACT_APP_API_URL}/quizzes`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(quizData),
        })
            .then((response) => {
                return response.json();  // Parse the response body as JSON
            })
            .then((data) => {

                if (data.message == "token_expired") {
                    openModal();
                }

                if (data.success) {

                    clearForm();

                    getQuizzes();

                    navigate('/editQuiz?quizId=' + data.insertId)

                }

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    const getQuizzes = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/quizzes`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }
                setQuizzes(data)
                setIsLoading(false)
            });
    }

    const handleUpdateQuiz = (quizId) => {
        // TODO: Implement update course API call
        navigate(`/editQuiz?quizId=${quizId}`)
    };

    const getQuizzesByCategory = async () => {

        setIsLoading(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/quizzes?categoryId=${selectedCategory}&keyWord=${searchKeyword}`, {
            method: 'GET',
            headers: headers,
        });

        const response = await request.json();

        if(response.message=="token_expired"){
            openModal();
            return;
        }

        setQuizzes(response);

        setIsLoading(false);

    }

    useEffect(() => {

        if (selectedCategory != "" || searchKeyword != "") {

            getQuizzesByCategory();

        } else if (selectedCategory == "") {

            getQuizzes();

        }

    }, [selectedCategory, searchKeyword])

    const handleDeleteCourse = (courseId) => {
        setSelectedID(courseId);
        setShowDeleteModal(true);
    };

    const deleteQuiz = async () => {

        if (!selectedID) {
            toast.error("Something isn't right, try again!", {
                position: "top-center"
            })
            return;
        }

        setDeleting(true);

        const postData = { id: selectedID };

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteQuiz`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: headers,
            })

            const response = await request.json();

            if (response.feedback == "success") {
                getQuizzes();
                setSelectedID("");
                toast.success("Course deleted successfully", {
                    position: "top-center"
                })
                setShowDeleteModal(false)
            } else {
                toast.error(response.message, {
                    position: "top-center"
                })
            }

            setDeleting(false);

        } catch (e) {
            setDeleting(false);
        }

    }

    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('csv', file);
        formData.append('category_id', newQuiz.category_id);

        if (!newQuiz.category_id || !file) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }

        setUploading(true);

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/uploadQuiz`, {
                method: 'POST',
                body: formData,
                headers: headers,
            });

            const response = await request.json();
            setUploading(false);
            if (response.success) {

                getQuizzes();
                toast.success("Quiz uploaded successfully!", {
                    position: "top-center"
                })
                setShowUploadModal(false);
                return;

            } else {

                toast.error(response.message, {
                    position: "top-center"
                })
                return;

            }

        } catch (err) {
            setUploading(false);
            toast.error("An error occurred ", {
                position: "top-center"
            })
            return;
        }
    };

    const getCourses = async (categoryId) => {

        setLoading(true);
        setCourse(null)

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/courses/${categoryId}`,{
                method: 'GET',
                headers: headers,
            });
            const response = await request.json();

            if(response.message=="token_expired"){
                openModal();
                return;
            }

            const formattedResponse = response.data.map((row) => ({
                value: row.id,
                label: row.name
            }))
            setCourses(formattedResponse)
            setLoading(false);

        } catch (e) {
            setLoading(false);
        }

    }

    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: "#ced4da",
            ":hover": {
                borderColor: "#ced4da",
            },
            boxShadow: "none",
        }),
    };

    function clearForm() {
        setCourse({});
        setCourses([]);
        setNewQuiz({ name: "", category_id: "", courseId: "" })
    }

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col>
                            <h1>Quizzes</h1>
                        </Col>
                    </Row>

                    <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => setShowModal(true)}>
                                    Add Quiz
                                </Button>
                                &nbsp;
                                <Button variant="primary" onClick={() => setShowUploadModal(true)}>
                                    Upload Quiz
                                </Button>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Col>

                                <Form.Group>
                                    <Form.Label>Filter By Category</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    >
                                        <option value="">All Categories</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                            </Col>

                            <Col>

                                <Form.Group>
                                    <Form.Label>Filter By Course Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>

                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Status</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quizzes.map((quiz) => (
                                                <tr key={quiz.id}>
                                                    <td>{quiz.quizName}</td>
                                                    <td>{quiz.categoryName}</td>
                                                    <td>
                                                        {quiz.status == 1 &&
                                                            <>Active</>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Button variant="info" onClick={() => handleUpdateQuiz(quiz.id)}>
                                                            View
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => handleDeleteCourse(quiz.id)}>
                                                            Delete
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>

                            </Col>
                        </Row>

                        {/* Modal for Creating New Quiz */}
                        <Modal show={showModal} onHide={() => {
                            clearForm();
                            setShowModal(false);
                        }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Quiz</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Quiz Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newQuiz.name}
                                            onChange={(e) => setNewQuiz({ ...newQuiz, name: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={newQuiz.category_id}
                                            onChange={(e) => {
                                                setNewQuiz({ ...newQuiz, category_id: e.target.value })
                                                getCourses(e.target.value);
                                            }}
                                        >
                                            <option value="">
                                                Select quiz category
                                            </option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <br />
                                    {courses.length > 0 &&
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label>Course</Form.Label>

                                            <Col sm={12}>
                                                <Select
                                                    value={course}
                                                    onChange={(selected) => {
                                                        setCourse(selected);
                                                        setNewQuiz({ ...newQuiz, courseId: selected.value })
                                                    }}
                                                    options={courses}
                                                    isSearchable={true}
                                                    placeholder="Search for a course"
                                                    styles={customStyles}
                                                />
                                            </Col>
                                        </Form.Group>
                                    }
                                </Form>

                                {loading &&
                                    <div>
                                        <span className="loader"></span>
                                    </div>
                                }

                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="primary" onClick={handleCreateQuiz}>
                                    Add Quiz
                                </Button>

                                <Button variant="secondary" onClick={() => {
                                    setShowModal(false);
                                    clearForm();
                                }}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                    </div>

                </Col>

            </Row>

            {/* Modal for Deleting Student */}
            <Modal show={showDeleteModal} onHide={() => {
                setSelectedID("");
                setShowDeleteModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        This is a permanent and irreversible action!
                    </p>
                    <p>
                        Click yes if you are sure!!!
                    </p>

                    {deleting &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={deleteQuiz}>
                        Yes
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setSelectedID("");
                        setShowDeleteModal(false);
                    }}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Uploading New Quiz */}
            <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload New Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group>
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                as="select"
                                value={newQuiz.category_id}
                                onChange={(e) => {
                                    setNewQuiz({ ...newQuiz, category_id: e.target.value })

                                }}
                            >
                                <option value="">
                                    Select Quiz category
                                </option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <br />
                        <Form.Group>
                            <Form.Label>Quiz File</Form.Label>
                            <div>
                                <input type="file" accept=".csv" onChange={onFileChange} />
                            </div>
                        </Form.Group>

                    </Form>

                    {uploading &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={onUpload}>
                        Upload
                    </Button>

                    <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>



        </>
    );
}

export default Quizzes;
