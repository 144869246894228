import React, { useState, useEffect } from "react";
import fetch from 'node-fetch';
import Header from "../components/header";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useDispatch, useSelector } from 'react-redux';
import { loginStart, loginSuccess, loginFailure } from '../redux/auth/authenticationSlice';
import { useHistory, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";


function Onboard(props) {

    const [dataLoading, setDataLoading] = useState(false);

    const [fullName, setFullname] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [verifying, setVerifying] = useState(true);
    const [feedback, setFeedback] = useState("");
    const [email, setEmail] = useState("");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { invitationCode } = useParams();

    const checkInvitationCode = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/checkInvitationCode?code=${invitationCode}`);

            const response = await request.json();

            console.log(response);

            if (response.feedback == "invalid") {
                setVerifying(false);
                setFeedback("Invalid invitation link");
                toast.error("Invalid code", {
                    position: "top-center"
                })
                return;

            } else if (response.feedback == "completed") {
                setVerifying(false);
                toast.success(response.message, {
                    position: "top-center"
                })
                navigate("/Login");
                return;
            } else if (response.feedback == "verified") {

                setEmail(response.email)
                setVerifying(false);
                setFeedback("");

            } else if (response.feedback == "success") {

                setEmail(response.email)
                setVerifying(false);
                setFeedback("");

            } else if (response.feedback == "error") {

                setVerifying(false);
                toast.error(response.message, {
                    position: "top-center"
                })
                return;

            }

        } catch (e) {
            console.log(e);
            toast.error("System Error", {
                position: "top-center"
            })
            return;
        }

    }

    useEffect(() => {
        checkInvitationCode();
    }, [])

    const completeSignup = async () => {

        if (!email) {
            toast.error("Invalid request", {
                position: "top-center"
            })
            return;
        }

        if (!fullName || !username || !password) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }

        setDataLoading(true);

        const body = { email: email, fullName: fullName, username: username, password: password, requestKey: process.env.REACT_APP_SERVER_KEY };

        const request = await fetch(`${process.env.REACT_APP_API_URL}/completeSignup`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });

        const response = await request.json();

        console.log(response);
        
        if (response.feedback == "success") {

            toast.success("Registration completed successfully", {
                position: "top-center"
            })

            navigate('/Login');
            return;

        } else if (response.feedback == "completed") {

            toast.success("Registration already completed", {
                position: "top-center"
            })

            navigate('/Login');
            return;

        } else if (response.feedback == "error") {

            toast.error(`${response.message}`, {
                position: "top-center"
            })

        }

        setDataLoading(false);

    }

    return (
        <>
            <Header />
            <>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8" style={{ padding: 20 }}>

                            <Card className="shadow-lg" style={{ border: 0 }}>
                                <Card.Body>
                                    <h1 className="text-center">Moonrepublic Onboarding</h1>
                                    <p className="text-center"></p>
                                    <p>{ }</p>

                                    {verifying &&
                                        <Row>
                                            <center>
                                                <span className="loader1"></span>
                                                <p>Verifying, please wait....</p>
                                            </center>
                                        </Row>
                                    }
                                    {!verifying && feedback != "" &&
                                        <>
                                            <hr />
                                            <h3 className="text-center">{feedback}</h3>
                                            <hr />
                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        navigate("/Login")
                                                    }}
                                                >
                                                    Go Home
                                                </Button>
                                            </div>
                                        </>
                                    }

                                    {!verifying && feedback == "" &&
                                        <>
                                            <h5 className="text-center">Choose a username and password to continue</h5>
                                            <hr />
                                            <Row>
                                                <div className="col-lg-7 col-md-7 mx-auto">
                                                    <Form>

                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Full name</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter full name"
                                                                value={fullName}
                                                                onChange={(e) => {
                                                                    setFullname(e.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Username</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter username"
                                                                value={username}
                                                                onChange={(e) => {
                                                                    setUsername(e.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control type="password" placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => {
                                                                    setPassword(e.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>

                                                        <div className="text-center">
                                                            <Button
                                                                variant="primary"
                                                                disabled={dataLoading}
                                                                onClick={!dataLoading ? completeSignup : null}
                                                            >
                                                                {dataLoading ? 'Loading...' : 'Continue'}
                                                            </Button>
                                                        </div>

                                                    </Form>
                                                </div>
                                            </Row>
                                        </>

                                    }
                                </Card.Body>
                            </Card>

                        </div>
                    </div>
                </div>

            </>
        </>
    )

}

export default Onboard;