// ModalContext.js
import React, { useState, createContext, useContext } from 'react';
import { Form, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {

    //const navigate = useNavigate();
    const dispatch = useDispatch();

    const username = useSelector((e) => { if (e.user.user) { return e.user.user.username } });

    const [isModalOpen, setModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [dataLoading, setDataLoading] = useState(false)

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const processLogin = async () => {

        setDataLoading(true);

        const body = { username: username, password: password };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/processLogin`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();

        if (data.feedback == "not_found") {

            setDataLoading(false);
            toast.error("User not found", {
                position: "top-center"
            })
            return;

        } else if (data.feedback == "wrong_pass") {

            setDataLoading(false);
            toast.error("Wrong password", {
                position: "top-center"
            })
            return;

        } else if (data.feedback == "success") {

            toast.success("Login succesfull", {
                position: "top-center"
            })
            dispatch({ type: 'LOGOUT' });

            dispatch({ type: 'LOGIN', user: { username: username, loggedIn: true, userId: data.data.userId, permissions: data.data.permissions, accessToken: data.data.accessToken } });

            setShowModal(false)

            return;

        } else {
            setDataLoading(false);
            toast.error("An unknown error occurred, please contact admin", {
                position: "top-center"
            })
            return;
        }

        //alert("Hello")

        setDataLoading(false);

    }

    return (
        <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
            {children}

            <Modal show={showModal} onHide={() => setShowModal(true)}>

                <Modal.Header closeButton>
                    <Modal.Title>Enter your password to continue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={() => processLogin()} disabled={dataLoading}>
                        {dataLoading ? <>Loading...</> : <>Continue</>}
                    </Button>

                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </ModalContext.Provider>
    );
};
