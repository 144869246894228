import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate, useParams } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";
import { useModal } from "./ModalContext";

function EditApplications() {
  const userId = useSelector((e) => {
    if (e.user.user) {
      return e.user.user.userId;
    }
  });

  const { openModal } = useModal();
  const accessToken = useSelector((state) => state.user.user.accessToken);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const { applicationsID } = useParams();

  const navigate = useNavigate();
  const [staff, setStaff] = useState({});
  const [userStatus, setUserStatus] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [key, setKey] = useState("permissions");

  const [permissions, setPermissions] = useState([]);
  const [actionType, setActionType] = useState("addPermission");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [permissionId, setPermissionId] = useState("");
  const [adding, setAdding] = useState(false);
  const [module, setModule] = useState("");
  const [userPermissions, setUserPermissions] = useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const modules = [
    {
      value: "CATEGORIES",
      label: "Categories",
    },
    {
      value: "COURSES",
      label: "Courses",
    },
    {
      value: "QUIZZES",
      label: "Quizzes",
    },
    {
      value: "ENROLLMENTS",
      label: "Enrollments",
    },
    {
      value: "PAYMENTS",
      label: "Payments",
    },
    {
      value: "STAFF",
      label: "Staff",
    },
    {
      value: "STUDENTS",
      label: "Students",
    },
  ];

  const loadStaff = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/loadMenber?userId=${applicationsID}`,
        {
          method: "GET",
          headers,
        }
      );

      const response = await request.json();

      if (response.message == "token_expired") {
        openModal();
        return;
      }

      setStaff(response.data[0]);

      console.log(response);

      setIsLoading(false);
    } catch (e) {
      toast.error("An error occurred", {
        position: "top-center",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId == null) {
      //navigate('/quizzes');
      return;
    }
generatePassword();
    loadStaff();
    // getPermissions();
  }, []);


    const generatePassword = () => {
    const randomNumber = Math.floor(Math.random() * 1000000);
    setPassword(randomNumber.toString());
  };

  const updateStaff = async (e) => {
    e.preventDefault();
  
   

   
    setIsLoading(true);

    const postData = { userId: applicationsID, Newpassword: password };

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/updateApplicationsUsers`,
        {
          method: "POST",
          body: JSON.stringify(postData),
          headers,
        }
      );

      const response = await request.json();

      if (response.message == "token_expired") {
        openModal();
        return;
      }

      console.log(response);

      if (response.feedback == "success") {
        toast.success("Profile updated successfully", {
          position: "top-center",
        });
      } else {
        toast.error("An error occurred22", {
          position: "top-center",
        });
      }

      setIsLoading(false);
    } catch (e) {
      toast.error("An error occurred1", {
        position: "top-center",
      });

      setIsLoading(false);
    }
  };

  const getPermissions = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/permissions?userId=${applicationsID}`,
        {
          method: "GET",
          headers,
        }
      );

      const response = await request.json();

      if (response.message == "token_expired") {
        openModal();
        return;
      }
      if (response.data.length > 0) {
        setPermissions(response.data);
      }
    } catch (e) {}
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#ced4da",
      ":hover": {
        borderColor: "#ced4da",
      },
      boxShadow: "none",
    }),
  };

  function handleSubmit() {
    if (actionType == "addPermission") {
      addPermissions();
    }
  }

  const addPermissions = async () => {
    if (!module.value) {
      toast.error("Module is required!", {
        position: "top-center",
      });
      return;
    }

    setAdding(true);

    const postData = {
      userId,
      applicationsID,
      module: module.value,
      permissions: userPermissions,
    };

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/createPermissions`,
        {
          method: "POST",
          body: JSON.stringify(postData),
          headers,
        }
      );

      const response = await request.json();

      if (response.message == "token_expired") {
        openModal();
        return;
      }

      if (response.feedback == "success") {
        getPermissions();
        toast.success("Permission added successfully", {
          position: "top-center",
        });
        setShowModal(false);
      } else {
        toast.error(response.message, {
          position: "top-center",
        });
      }

      setAdding(false);
    } catch (e) {
      setAdding(false);
    }
  };

  async function deletePermision() {
    if (!permissionId) {
      toast.error("Something isn't right, try again!", {
        position: "top-center",
      });
      return;
    }

    setAdding(true);

    const postData = { permissionId, userId: applicationsID };

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/deletePermission`,
        {
          method: "POST",
          body: JSON.stringify(postData),
          headers,
        }
      );

      const response = await request.json();

      if (response.feedback == "success") {
        getPermissions();
        toast.success("Permission deleted successfully", {
          position: "top-center",
        });
        setShowDeleteModal(false);
      } else {
        toast.error(response.message, {
          position: "top-center",
        });
      }

      setAdding(false);
    } catch (e) {
      setAdding(false);
    }
  }

  return (
    <>
      <Header />
      <Row className="home_outer">
        <Menu />

        <Col className="right_home">
          {isUpdating && (
            <div>
              <span className="loader"></span>
            </div>
          )}

          <Row>
            <Col lg="11">
              <h1>{pageTitle}</h1>

              <div
                className="shadow-lg"
                style={{
                  padding: 10,
                  border: "1px solid #c7c7c7",
                  borderRadius: 5,
                  minHeight: 400,
                  overflow: "hidden",
                }}
              >
                {isLoading && (
                  <div>
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                      <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                      <Placeholder xs={8} />
                    </Placeholder>

                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                      <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                      <Placeholder xs={8} />
                    </Placeholder>

                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                      <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                      <Placeholder xs={8} />
                    </Placeholder>

                    <Placeholder.Button variant="primary" xs={6} />
                  </div>
                )}
                {!isLoading && (
                  <>
                    <Row>
                      <Col lg="12" className="">
                        <h1>
                         
                          Applications Profile Details
                        </h1>
                        <hr />
                        <Row>
                          <Col lg="7">
                            <Form id="myForm" onSubmit={updateStaff}>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> Name</b>
                                </Form.Label>
                                <Col sm={8}>
                                  {staff.title} {staff.firstName}{" "}
                                  {staff.middleName} {staff.lastName}
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Gender</b>
                                </Form.Label>
                                <Col sm={8}>{staff.Gender}</Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Date of birth</b>
                                </Form.Label>
                                <Col sm={8}>{staff.DOB}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Email</b>
                                </Form.Label>
                                <Col sm={8}>{staff.email}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Phone No.</b>
                                </Form.Label>
                                <Col sm={8}>{staff.phoneNumber}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Occupation.</b>
                                </Form.Label>
                                <Col sm={8}>{staff.Occupation}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Religious.</b>
                                </Form.Label>
                                <Col sm={8}>{staff.Religious}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> State Of Origin</b>
                                </Form.Label>
                                <Col sm={8}>{staff.statesName_origin}</Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>Local Government Of Origin.</b>
                                </Form.Label>
                                <Col sm={8}>
                                  {staff.lga_origin}
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b>State Of Residence.</b>
                                </Form.Label>
                                <Col sm={8}>{staff.statesName}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> Local Government Of Residence</b>
                                </Form.Label>
                                <Col sm={8}>{staff.lga}</Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> VIN</b>
                                </Form.Label>
                                <Col sm={8}>{staff.voterIdNo}</Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> Ward</b>
                                </Form.Label>
                                <Col sm={8}>{staff.wards}</Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label column sm={4}>
                                  <b> Polling Unit. </b>
                                </Form.Label>
                                <Col sm={8}>{staff.PU}</Col>
                              </Form.Group>

                             
                               
                             

                              <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 10, offset: 2 }}>
                                  <Button type="submit">Approve</Button>
                                </Col>
                              </Form.Group>
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Modal for Creating New Permission */}
      <Modal
        show={showModal}
        onHide={() => {
          setActionType("");
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {actionType == "addPermission" && <>Add Permission</>}
            {actionType != "addPermission" && <>Update Permission</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={4}>
                Module
              </Form.Label>
              <Col sm={8}>
                <Select
                  value={module}
                  onChange={(selected) => {
                    setModule(selected);
                  }}
                  options={modules}
                  isSearchable={true}
                  placeholder="Search for a module..."
                  styles={customStyles}
                />
              </Col>
            </Form.Group>

            <Row>
              <Col>
                <h5>Permissions</h5>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId={`isAnswer`}>
                  <Form.Label>Create</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="create"
                    checked={userPermissions.create}
                    onChange={(e) => {
                      setUserPermissions((previousPemissions) => ({
                        ...previousPemissions,
                        create: e.target.checked,
                      }));
                      console.log(userPermissions);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId={`isAnswer`}>
                  <Form.Label>Read</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="read"
                    checked={userPermissions.read}
                    onChange={(e) => {
                      setUserPermissions((previousPemissions) => ({
                        ...previousPemissions,
                        read: e.target.checked,
                      }));
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId={`isAnswer`}>
                  <Form.Label>Update</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="update"
                    checked={userPermissions.update}
                    onChange={(e) => {
                      setUserPermissions((previousPemissions) => ({
                        ...previousPemissions,
                        update: e.target.checked,
                      }));
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId={`isAnswer`}>
                  <Form.Label>Delete</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="delete"
                    checked={userPermissions.delete}
                    onChange={(e) => {
                      setUserPermissions((previousPemissions) => ({
                        ...previousPemissions,
                        delete: e.target.checked,
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            {adding && (
              <center>
                <div>
                  <span className="loader"></span>
                </div>
              </center>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmit()}>
            {actionType == "addPermission" ? (
              <>Add Permission</>
            ) : (
              <>Update Permission</>
            )}
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              setActionType("");
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Deleting Permission */}
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setPermissionId("");
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete????</p>

          {adding && (
            <center>
              <div>
                <span className="loader"></span>
              </div>
            </center>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deletePermision}>
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setPermissionId("");
              setShowDeleteModal(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditApplications;
